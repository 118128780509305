import React from "react";
import MainImage from "./MainImage";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";

const modifySingleChars = string => string.replace ? string.replace(/ ([a-zA-Z]) /g, ' $1' + '\u00A0') : string;

const parseChildren = (ch) => ch.map(b => modifySingleChars(b))


const serializers = {
  block: ({children}) => (<p>{parseChildren(children)}</p>),
  types: {
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    videoEmbed: ({ node }) => <ReactPlayer className="mt-6 mb-6" url={node.url} controls />,
    instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
    },
  },
};

export default serializers;
